<template>
  <div class="warp">
    <el-form inline size="mini">
      <el-form-item label="Source of Data:">
        <select-box
          width="240"
          :options="originData"
          :value.sync="extraData.dataSourceCode"
          placeholder="Please select source of data"
        />
      </el-form-item>
      <el-form-item label="Status:">
        <select-box
          width="240"
          :options="statusOptions"
          :value.sync="extraData.status"
        />
      </el-form-item>
      <el-form-item label="source Name">
        <el-input
          style="width:240px"
          clearable
          v-model="extraData.thirdName"
          placeholder="Please typing in source name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Standard Name:">
        <select-box
          :url="STD_SEARCH_SPORT"
          :queryOrderParams="{ page: 1, size: 500 }"
          :value.sync="extraData.standardName"
          search-key="name"
          label-key="sportsName"
          value-key="sportsName"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
    >
      <el-table-column
        label="Source of Data"
        width="140"
        align="center"
        prop="dataSource"
      >
        <template v-slot="{ row }">
          {{ originMapping[row.dataSourceCode] }}
        </template>
      </el-table-column>
      <el-table-column
        class-name="yellow-col"
        label="source ID"
        width="140"
        align="center"
        prop="sourceSportId"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        class-name="yellow-col"
        label="Standard ID"
        min-width="160"
        align="center"
        prop="standardId"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        class-name="green-col"
        label="source Name"
        min-width="180"
        align="center"
        prop="thirdSportsName"
      >
        <template v-slot="{ row }">
          <div
            :class="{
              red: row.status && row.thirdSportsName !== row.standardSportsName
            }"
          >
            {{ row.thirdSportsName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        class-name="green-col"
        label="Standard Name"
        min-width="160"
        align="center"
        prop="standardSportsName"
      ></el-table-column>
      <el-table-column label="Status" align="center" prop="status" width="120">
        <template v-slot="{ row }">
          <p :class="{ red: !row.status, green: row.status }">
            • {{ row.status ? 'Mapped' : 'UnMapped' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Remark"
        min-width="180"
        align="center"
        prop="remark"
      >
        <template v-slot="{ row }">
          <dropdown
            :row="{
              thirdId: row.thirdSportsId,
              dataSourceCode: row.dataSourceCode,
              standardId: row.standardId
            }"
            :methodStr="methodStr"
            :text="`${row.remark || 'Please select'}`"
            @get-list="getList"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="edit(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Log" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="'Third_Soprt'" :dataId="row.thirdSportsId" />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin-top: 20px">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="130px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Source Name:">
          {{ dialogForm.thirdName }}
        </el-form-item>
        <el-form-item label="Standard Name:" prop="standardId" required>
          <select-box
            :url="'sport/validList'"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.standardId"
            :label="dialogForm.standardName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Remark:" prop="remark">
          <el-input
            v-model="dialogForm.remark"
            placeholder="Please enter remark"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="dialogSubmit"
            :loading="dialog.isLoading"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSportMappingList as getData,
  editSportMapping as editData
} from '@/service/subscribe'
import TableMixin from '@/mixins/subscibe/table'
import Dropdown from '@/components/Dropdown'

export default {
  name: 'SportsMapping',
  mixins: [TableMixin],
  components: { Dropdown },
  data() {
    return {
      methodStr: 'editSportMapping',
      extraData: {
        standardName: '',
        status: ''
      },
      rules: {
        standardId: [
          {
            message: 'Please typing select standard name',
            required: true
          }
        ]
      }
    }
  },
  methods: {
    getData,
    editData,
    edit(row) {
      const data = {
        thirdId: row.thirdSportsId,
        standardId: row.standardId,
        standardName: row.standardSportsName,
        remark: row.remark,
        thirdName: row.thirdSportsName
      }
      this.dialogEdit(data)
    }
  }
}
</script>
